<template>
  <div class="scroll-wrapper">
      <div class="loading-screen" ref="loadingScreen">
        <video class="background-video"
               ref="backgroundVideo" autoplay muted playsinline @ended="onVideoEnd" v-if="isMobile">
          <source src="@/assets/ouverture-at-signature-mobile.mp4" type="video/mp4" />
        </video>

        <video class="background-video" ref="backgroundVideo" autoplay muted playsinline @ended="onVideoEnd" v-else-if="!isMobile">
          <source src="@/assets/ouverture-at-signature.mp4" type="video/mp4" />
        </video>
      </div>


    <div class="logo" ref="logoDiv">
        <img class="logo-img" src="@/assets/logo.png" alt="logo-at-signature" ref="logoImg" @click="goToTop">

      <video class="logo-video" ref="logoVideo" muted playsinline loop id="background-video">
        <source src="@/assets/home.mp4" type="video/mp4" />
      </video>


      <div class="dream" ref="dreamText">
        <div class="dream-word" ref="word1">Rê</div>
        <div class="dream-word no" ref="word2" style="margin-left: -55px; margin-right: -55px; opacity: 0;">no</div>
        <div class="dream-word" ref="word3">ver</div>
      </div>

      <div class="scroll" ref="scrollGif">
        <div class="bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'LoadingScreen',

  data() {
    return {
      part1: "Rê",
      part2: "",
      part3: "ver",
      isMobile: false
    };
  },

  mounted() {
    this.isMobile = window.innerWidth < 768
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768
    })

    ScrollTrigger.create({
      start: 'top top',
      onEnter: () => {
        gsap.to(this.$refs.scrollGif, { opacity: 0, duration: 0.5 });
      },
      onLeaveBack: () => {
        gsap.to(this.$refs.scrollGif, { opacity: 1, duration: 0.5 });
      },
    });


    ScrollTrigger.create({
      trigger: '.scroll-wrapper',
      start: 'top top',
      end: 'bottom-=10% bottom',
      scrub: true,
      onUpdate: (self) => {
        const progress = self.progress;
        const newMarginValue = -55 + (progress * 55);

        gsap.to(this.$refs.word2, {
          opacity: progress,
          marginLeft: newMarginValue+'px',
          marginRight: newMarginValue+'px'
        });
      }
    });

    ScrollTrigger.create({
      trigger: '.scroll-wrapper',
      start: 'top top',
      end: 'bottom bottom',
      scrub: true,


      onLeave: () => {
        this.$refs.logoDiv.style.position = 'absolute';
        this.$refs.logoDiv.style.bottom = '0px';
        this.$refs.logoDiv.style.top = 'unset';
        this.$refs.logoVideo.style.position = 'absolute';
        this.$refs.logoVideo.style.bottom = '0px';
        this.$refs.dreamText.style.position = 'absolute';
        this.$refs.scrollGif.style.opacity = '0';
      },

      onEnterBack: () => {
        this.$refs.logoDiv.style.position = 'fixed';
        this.$refs.logoDiv.style.bottom = 'unset';
        this.$refs.logoDiv.style.top = '0px';
        this.$refs.logoVideo.style.position = 'fixed';
        this.$refs.logoVideo.style.bottom = 'unset';
        this.$refs.dreamText.style.position = 'fixed';
        this.$refs.scrollGif.style.opacity = '1';
      }
    });


  },

  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768
    })
  },

  methods: {
    onVideoEnd() {
      this.$refs.backgroundVideo.style.display = 'none';
      this.$refs.logoDiv.style.opacity = '1';
      this.$refs.loadingScreen.style.zIndex = '-1';
      this.$refs.logoVideo.play();

      if(!this.isMobile) {
        gsap.to(this.$refs.logoImg, { duration: 2, top: '6%', scale: 0.25 });
      } else{
        gsap.to(this.$refs.logoImg, { duration: 2, top: '53px', scale: 0.5 });
      }

      gsap.to(this.$refs.logoDiv, { duration: 2, backgroundColor: "rgba(11, 26, 54, 0.99)" });
      gsap.to(this.$refs.logoVideo, { duration: 2, opacity: .65, delay: 0.15 });

      gsap.to(this.$refs.dreamText, {opacity: 1, top: "42%", duration: 1, delay: 1.5})
      gsap.to(this.$refs.scrollGif, {opacity: 1, duration: 1, delay: 2.5})

      this.goToTop();

      document.body.style.overflow = 'auto';
    },

    goToTop() {
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style scoped lang="scss">

.scroll-wrapper{
  height: 180vh;
  position: relative;
  width: 100%;
  max-width: 100%;
  //z-index: 999;
}

.loading-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 999;
}
.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 99;
  transform: translate(-50%, -50%);
}

.logo{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: rgb(28, 39, 56);
  opacity: 0;
}

.logo-img {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 210;
  opacity: 1;
  width: 880px;
  height: auto;

  @media (max-width: 768px) {
    width: 75%;
  }
}

.logo-video{
  width: 100%;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
  position: fixed;
  top: 0;
  opacity: 0;
}

.dream{
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  text-align: center;
  font-size: 100px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 100;
  color: #fff;
  opacity: 0;
}

.dream-word {
  display: inline-block;
}

.scroll{
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  width: 24px;
  height: 40px;
  opacity: 0;
  border: 1px solid #fff;
  border-radius: 30px;
}

.scroll .bar{
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 2px;
  height: 10px;
  background-color: #fff;
  border-radius: 30px;
}

.scroll .bar:nth-child(1){
  animation: scroll 2s infinite;
}

/* scroll animation, the bar is going from 5% to 45% */
@keyframes scroll {
  0%{
    top: 10%;
  }

  50%{
    top: 50%;
  }

  80%{
    top: 10%;
  }
}

</style>
