<template>
  <div class="premium" id="premium" ref="premium">
    <video autoplay muted loop playsinline ref="videoPremium" class="premium__video">
      <source src="@/assets/at-premium-video.mp4" type="video/mp4">
    </video>

    <div class="premium__wrapper">
      <p ref="textPremium">
        Pour tous vos projets de travaux de rénovation immobilière, extension, aménagement, Activ Travaux Premium
        s’engage via son offre de Contractant Général sur un délai de livraison et un prix ferme et définitif
      </p>
    </div>

    <div class="premium__bottom-animation" ref="premiumAnimation">
        <a href="https://www.activ-travaux.com/" class="premium__bottom-animation__btn" ref="premiumBtn">Découvrez l'offre premium</a>
        <img ref="atDiamant" src="@/assets/at-premium.png" alt="AtPremiumText" class="premium__bottom-animation__img"/>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "at-premium",

  mounted() {
    this.atDiamant = this.$refs["atDiamant"];
    this.premiumBtn = this.$refs["premiumBtn"];

    gsap.set(this.atDiamant, { x: -400 });
    gsap.set(this.premiumBtn, { opacity: 0 });

    ScrollTrigger.create({
      trigger: this.$refs["premium"],
      start: "top top",
      //on enter gsap.to
      onEnter: () => {
        gsap.to(this.atDiamant, {x: 0, duration: .75, ease: "power2.out"})
        gsap.to(this.premiumBtn, {opacity: 1, duration: 1, ease: "power2.out"})
      }
    });
  },

  methods: {}
};
</script>

<style scoped lang="scss">
.premium{
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  &__video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__wrapper{
    background-color: rgba(11, 26, 54, 0.4);
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding: 1rem;
    align-items: center;

      p{
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        width: 700px;
        max-width: 100%;
        font-size: 1.5rem;
        font-weight: 200;
        text-align: center;
        font-family: "Lexend Deca", sans-serif;
        padding: 16px;

        @media (max-width: 768px) {
          top: 25%;
        }
    }
  }

  &__bottom-animation {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &__img{
      width: 255px;
      max-width: 100%;
    }


    &__btn{
      text-transform: uppercase;
      background-color: transparent;
      border: 2px solid #fff;
      color: #fff;
      font-size: 1rem;
      padding: 10px 15px;
      font-family: "Lexend Deca", sans-serif;
      margin-right: 2rem;
      cursor: pointer;
      font-weight: 300;
      text-decoration: none;

      @media (max-width: 768px) {
        margin-top: 6.5rem;
        margin-bottom: 1rem;
        margin-right: 0;
      }
    }
  }
}
</style>
