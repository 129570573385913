<template>
  <div class="contact" id="contact">
    <div class="navbar">
      <ul>
        <div class="dot-container">
          <div v-for="(option, index) in options" :key="index" class="dot" :class="{ active: isActiveStep(index + 1) }" @click="goToStep(index + 1)">
            <p>{{ option.text }}</p>
          </div>
        </div>

        <li :class="{ active: currentStep === 2 || currentStep > 2 }"></li>
        <li :class="{ active: currentStep === 3 || currentStep > 3 }"></li>
        <li :class="{ active: currentStep === 4 || currentStep > 4 }"></li>
        <li :class="{ active: currentStep === 5 || currentStep > 5 }"></li>
      </ul>
    </div>

    <div class="transition-container">
      <transition name="fade" mode="out-in">
        <div :key="currentStep" class="step" v-if="currentStep === 1">
          <div class="step-content">
            <div class="step-photo">
              <img src="@/assets/contact/1.png" alt="">
            </div>
            <div class="step-form">
              <p class="title">Quel projet pouvons-nous concevoir avec vous ?</p>

              <div class="checkbox-container">
                <label v-for="(checkbox, index) in projet" :key="index" class="checkbox-label">
                  <input type="checkbox" v-model="checkbox.checked" @change="selectInput(projet, true)" />
                  <span class="checkbox-style">
                    <font-awesome-icon class="tick" :icon="['fas', 'check']" />
                    <p>{{ checkbox.text }}</p>
                  </span>
                </label>

                <button class="suivant-button" @click="nextStep">Suivant <div class="arrow"></div></button>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div :key="currentStep" class="step" v-if="currentStep === 2">
          <div class="step-content">
            <div class="step-photo">
              <img src="@/assets/contact/2.png" alt="">
            </div>
            <div class="step-form">
              <div class="go-back" @click="goBack">
                <div class="arrow"></div> Retour
              </div>
              <p class="title">Pour quel type de bien ?</p>
              <div class="checkbox-container">
                <label v-for="(checkbox, index) in bien" :key="index" class="checkbox-label">
                  <input type="checkbox" v-model="checkbox.checked" @change="selectInput(bien, false)" @click="nextStep"/>
                  <span class="checkbox-style">
                    <font-awesome-icon class="tick" :icon="['fas', 'check']" />
                    <p>{{ checkbox.text }}</p>
                  <div class="arrow">
                  </div>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div :key="currentStep" class="step" v-if="currentStep === 3">
          <div class="step-content">
            <div class="step-photo">
              <img src="@/assets/contact/3.png" alt="">
            </div>
            <div class="step-form">
              <div class="go-back" @click="goBack">
                <div class="arrow"></div> Retour
              </div>
              <p class="title">Quel est votre budget ?</p>
              <div class="checkbox-container">
                <label v-for="(checkbox, index) in budget" :key="index" class="checkbox-label">
                  <input type="checkbox" v-model="checkbox.checked" @change="selectInput(budget, false)" @click="nextStep"/>
                  <span class="checkbox-style">
                    <font-awesome-icon class="tick" :icon="['fas', 'check']" />
                    <p>{{ checkbox.text }}</p>
                  <div class="arrow">
                  </div>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div :key="currentStep" class="step" v-if="currentStep === 4">
          <div class="step-content">
            <div class="step-photo">
              <img src="@/assets/contact/4.png" alt="">
            </div>
            <div class="step-form">
              <div class="go-back" @click="goBack">
                <div class="arrow"></div> Retour
              </div>
              <p class="title">Apportez des précisions à votre projet</p>
              <div class="checkbox-container">
                <textarea class="message-details" placeholder="Votre message"></textarea>

                <button class="suivant-button" @click="nextStep">Suivant <div class="arrow"></div></button>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div :key="currentStep" class="step" v-if="currentStep === 5">
          <div class="coordonnees">
            <div class="go-back" @click="goBack">
              <div class="arrow"></div> Retour
            </div>
            <p class="title">Laissez-nous vos coordonnées pour être recontacté</p>

            <div class="coordonnees-wrapper">
              <div class="coordonnees-container">
                <div class="step-avantage">
                  <div class="avantage">
                    <font-awesome-icon class="tick" :icon="['fas', 'check']" />
                    Étude personnalisée de vos besoin
                  </div>
                  <div class="avantage">
                    <font-awesome-icon class="tick" :icon="['fas', 'check']" />
                    Professionnels sélectionnés avec soin
                  </div>
                  <div class="avantage">
                    <font-awesome-icon class="tick" :icon="['fas', 'check']" />
                    Garantie sur les délais et prix
                  </div>
                </div>
                <div class="step-form">
                  <!-- form -->
                  <form>
                    <div class="form-row">
                      <div class="input-group">
                        <input type="text" id="name" name="name" placeholder="Nom*" required />
                      </div>
                      <div class="input-group">
                        <input type="text" id="surname" name="surname" placeholder="Prénom*" required />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="input-group">
                        <input type="tel" id="telephone" name="telephone" placeholder="Téléphone*" required />
                      </div>
                      <div class="input-group">
                        <input type="email" id="email" name="email" placeholder="Adresse mail*" required />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="input-group full">
                        <input class="full" type="text" id="address" name="address" placeholder="Adresse*" required />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="input-group">
                        <input type="text" id="cp" name="cp" placeholder="CP*" required />
                      </div>
                      <div class="input-group">
                        <input type="text" id="ville" name="ville" placeholder="Ville*" required />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="consent-label">
                        <label class="switch">
                          <input type="checkbox" required>
                          <div class="slider">
                            <div class="circle">
                              <svg class="cross" xml:space="preserve" style="enable-background:new 0 0 512 512" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                </g>
            </svg>
                              <svg class="checkmark" xml:space="preserve" style="enable-background:new 0 0 512 512" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                </g>
            </svg>
                            </div>
                          </div>
                        </label>
                        J'accepte les conditions générales.
                      </div>
                    </div>

                    <div class="form-row">
                      <button class="validate" @click="sendContact">Valider</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

library.add(faCheck);

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'at-contact',
  components: {FontAwesomeIcon},

  data() {
    return {
      currentStep: 1,
      projet: [
        {text: "Rénovation globale", checked: false},
        {text: "Rénovation thermique", checked: false},
        {text: "Agrandissement / extension", checked: false},
        {text: "Autres", checked: false},
      ],

      bien: [
        {text: "Maison", checked: false},
        {text: "Appartement", checked: false},
        {text: "Local professionnel", checked: false},
        {text: "Immeuble", checked: false},
        {text: "Autres", checked: false},
      ],

      budget : [
        {text: "Moins de 100 000€", checked: false},
        {text: "De 100 à 300 000€", checked: false},
        {text: "De 300 à 600 000€", checked: false},
        {text: "Plus de 600 000€", checked: false},
      ],
    }
  },

  computed: {
    options() {
      return [
        { text: "Votre projet" },
        { text: "Votre bien" },
        { text: "Votre budget" },
        { text: "En détails" },
        { text: "Vos coordonnés" },
      ];
    },
  },

  methods: {
    nextStep() {
      if (this.currentStep < 5) {
        this.currentStep++;
      }
    },

    goToStep(step) {
      if(step < this.currentStep){
        this.currentStep = step;
      }
    },

    goBack() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    isActiveStep(step) {
      return step === this.currentStep || step < this.currentStep;
    },

    selectInput(data, isMultiple) {
      if(isMultiple) {
        return;
      }

      data.forEach((element, index) => {
        if(index !== data) {
          element.checked = false;
        }
      });
    },

    sendContact() {
      const formData = {
        "Projet": this.projet.filter(item => item.checked).map(item => item.text),
        "Bien": this.bien.filter(item => item.checked).map(item => item.text),
        "Budget": this.budget.filter(item => item.checked).map(item => item.text),
        "MessageDetails": this.messageDetails,
        "Nom": this.name,
        "Prénom": this.surname,
        "Téléphone": this.telephone,
        "Adresse mail": this.email,
        "Adresse": this.address,
        "Code postal": this.cp,
        "Ville": this.ville,
      };

      console.log("contact:", formData);
    },


  },

  mounted() {
  },
};
</script>

<style scoped lang="scss">
.transition-container {
  position: relative;
}


.contact {
  width: 100%;
  height: 100vh;
  background-color: #0B1A36;
  display: flex;
  flex-direction: column;
}

.navbar {
  display: block;
  margin: 0 auto;
  padding-top: 100px;
  width: 70%;

  @media (max-width: 768px) {
    p{
      display: none;
    }
  }
}

.navbar ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.dot-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 102%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  top: -0.5rem;

  .dot{
    z-index: 10;
    position: relative;
    cursor: pointer;
    height: 40px;
    width: 20px;
    background-color: transparent !important;

    &::after{
      width: 23px;
      height: 23px;
      content: "";
      background-color: #fff;
      clip-path: polygon(50% 0%, 100% 38%, 50% 100%, 0% 38%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      transition: all 0.3s ease-in-out;
    }

    p{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-family: "Lexend Deca", sans-serif;
      white-space: nowrap;
      margin-top: 20px;
    }
  }
}

.navbar li {
  position: relative;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  height: 1px;

  p{
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    font-family: "Lexend Deca", sans-serif;
    font-size: 1.25rem;
    background: linear-gradient(0deg, #AF191C 0%, #E83263 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
  }
}

.active {
  background-color: #E83263 !important;

  &::after{
    background-color: #E83263 !important;
  }

  p{
    color: #E83263 !important;
  }
}



.message-details{
  min-height: 300px;
  outline: none;
  padding: .5rem;
}

.step {
  display: block;
  margin: 0 auto;
  padding-top: 80px;
  width: 90%;
  height: 100%;

  @media (max-width: 768px) {
    padding-top: 5px;
  }
}

.go-back{
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 200;

  &:hover{
    .arrow{
      width: 35px;
    }
  }

  .arrow{
    background-color: #fff !important;
    transform: rotate(180deg);
    margin-right: 15px;

    &::after, &::before{
      background-color: #fff !important;
    }
  }
}

.coordonnees{
  display: flex;
  flex-direction: column;
  font-family: "Lexend Deca", sans-serif;

  .go-back{
    width: 1200px;
    max-width: 100%;
    margin: 1rem auto;
  }

  .coordonnees-wrapper{
    display: block;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    font-weight: 100;
  }

  .coordonnees-container{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    .step-form{
      width: 60% !important;

      @media (max-width: 768px) {
        width: 100% !important;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 2rem;
    }
  }

  .title{
    font-size: 2.5rem;
    color: #fff;
    width: 1200px;
    max-width: 100%;
    display: block;
    margin: 1rem auto;
    font-weight: 100;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .step-avantage{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 40%;

    @media (max-width: 768px) {
      width: 100%;
    }

    .form-title{
      font-size: 2rem;
      font-weight: 100;
      color: #FFFFFF;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        font-size: 1.6rem;
      }
    }


    .avantage{
      display: flex;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 200;
      margin-bottom: 1.5rem;

      svg{
        margin-right: 10px;
        color: #E83263;
      }

      @media (max-width: 768px) {
        font-size: 1.1rem;
      }
    }
  }
}

.step-content{
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .title{
    font-size: 2.5rem;
    color: #fff;
    font-family: "Lexend Deca", sans-serif;
    font-weight: 100;
    margin: 1rem 0;

    @media (max-width: 768px) {
      font-size: 1.6rem;
    }
  }


  .step-photo{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .step-form{
    width: 100%;
    max-width: 600px;
  }
}

form{
  display: flex;
  flex-direction: column;
  width: 100%;

  .form-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .full{
      width: 100% !important;
    }

    .input-group{
      width: 48%;
    }

    .validate{
      color: #fff;
      background-color: transparent;
      outline: none;
      padding: 10px 25px;
      font-size: 1.3rem;
      border: 1px solid #fff;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover{
        background-color: #fff;
        color: #0B1A36;
      }
    }
  }

  .input-group{
    width: 100%;

    input{
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff;
      outline: none;
      padding: 10px 25px;
      font-size: 1.3rem;
      width: 100%;
      font-weight: 100;

      &::-webkit-input-placeholder {
        color: #fff;
      }
    }
  }

  .consent-label{
    color: #fff;
    font-size: 1rem;
    display: flex;
    align-items: center;

    .switch{
      margin-right: 10px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}



.checkbox-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 200;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.checkbox-label input {
  display: none;
}

.checkbox-style {
  width: 100%;
  height: 60px;
  border: 2px solid #fff;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  transition: border-color 0.3s, color 0.3s;
  margin-bottom: 1rem;
  position: relative;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  p{
    margin-left: 15%;
    margin-right: 30%;
    white-space: nowrap;
  }
  .tick{
    position: absolute;
    left: 6%;
  }

  svg{
    color: transparent;
  }
}

.checkbox-label input:checked + .checkbox-style {
  border: 2px solid #E83263;

  p{
    background-image: linear-gradient(0deg, #AF191C 0%, #E83263 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  svg{
    color: #E83263;
  }
}

.suivant-button {
  margin-top: 30px;
  padding: 10px 25px;
  background-color: #fff;
  color: #0B1A36;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: fit-content;
  text-transform: uppercase;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  outline: none;
}

.arrow{
  position: relative;
  left: 10px;
  right: 20px;
  width: 30px;
  height: 2px;
  background-color: #0B1A36;
  transition: all ease-in-out 0.3s;

  &:before{
    content: "";
    position: absolute;
    top: -7px;
    right: 1px;
    width: 2px;
    height: 10px;
    background-color: #0B1A36;
    transform: rotate(-45deg);
    transition: all ease-in-out 0.3s;
  }

  &:after{
    content: "";
    position: absolute;
    bottom: -7px;
    right: 1px;
    width: 2px;
    height: 10px;
    background-color: #0B1A36;
    transform: rotate(45deg);
    transition: all ease-in-out 0.3s;
  }
}

.suivant-button:hover {
  background-color: #0B1A36;
  color: #fff;
  border: 1px solid #fff;

  .arrow{
    width: 35px;
    background-color: #fff;

    &:before{
      background-color: #fff;
    }

    &:after{
      background-color: #fff;
    }
  }
}


.switch {
  /* switch */
  --switch-width: 46px;
  --switch-height: 24px;
  --switch-bg: rgb(131, 131, 131);
  --switch-checked-bg: #507bce;
  --switch-offset: calc((var(--switch-height) - var(--circle-diameter)) / 2);
  --switch-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
  /* circle */
  --circle-diameter: 18px;
  --circle-bg: #fff;
  --circle-shadow: 1px 1px 2px rgba(146, 146, 146, 0.45);
  --circle-checked-shadow: -1px 1px 2px rgba(163, 163, 163, 0.45);
  --circle-transition: var(--switch-transition);
  /* icon */
  --icon-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
  --icon-cross-color: var(--switch-bg);
  --icon-cross-size: 6px;
  --icon-checkmark-color: var(--switch-checked-bg);
  --icon-checkmark-size: 10px;
  /* effect line */
  --effect-width: calc(var(--circle-diameter) / 2);
  --effect-height: calc(var(--effect-width) / 2 - 1px);
  --effect-bg: var(--circle-bg);
  --effect-border-radius: 1px;
  --effect-transition: all .2s ease-in-out;
}

.switch input {
  display: none;
}

.switch {
  display: inline-block;
}

.switch svg {
  -webkit-transition: var(--icon-transition);
  -o-transition: var(--icon-transition);
  transition: var(--icon-transition);
  position: absolute;
  height: auto;
}

.switch .checkmark {
  width: var(--icon-checkmark-size);
  color: var(--icon-checkmark-color);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.switch .cross {
  width: var(--icon-cross-size);
  color: var(--icon-cross-color);
}

.slider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: var(--switch-width);
  height: var(--switch-height);
  background: var(--switch-bg);
  border-radius: 999px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-transition: var(--switch-transition);
  -o-transition: var(--switch-transition);
  transition: var(--switch-transition);
  cursor: pointer;
}

.circle {
  width: var(--circle-diameter);
  height: var(--circle-diameter);
  background: var(--circle-bg);
  border-radius: inherit;
  -webkit-box-shadow: var(--circle-shadow);
  box-shadow: var(--circle-shadow);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: var(--circle-transition);
  -o-transition: var(--circle-transition);
  transition: var(--circle-transition);
  z-index: 1;
  position: absolute;
  left: var(--switch-offset);
}

.slider::before {
  content: "";
  position: absolute;
  width: var(--effect-width);
  height: var(--effect-height);
  left: calc(var(--switch-offset) + (var(--effect-width) / 2));
  background: var(--effect-bg);
  border-radius: var(--effect-border-radius);
  -webkit-transition: var(--effect-transition);
  -o-transition: var(--effect-transition);
  transition: var(--effect-transition);
}

/* actions */

.switch input:checked+.slider {
  background: var(--switch-checked-bg);
}

.switch input:checked+.slider .checkmark {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.switch input:checked+.slider .cross {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.switch input:checked+.slider::before {
  left: calc(100% - var(--effect-width) - (var(--effect-width) / 2) - var(--switch-offset));
}

.switch input:checked+.slider .circle {
  left: calc(100% - var(--circle-diameter) - var(--switch-offset));
  -webkit-box-shadow: var(--circle-checked-shadow);
  box-shadow: var(--circle-checked-shadow);
}

</style>
