<template>
  <div class="architectes" id="architectes">
    <h1 class="la-signature" ref="architectesImg">La Signature</h1>
    <h1 class="title" ref="architectesTitle">de l'architecte</h1>

    <p ref="textDescription">
      Dans le cadre de rénovations complexes, extension, surélévation, Signature Activ Travaux vous met en relation
      avec son réseau d’architectes partenaires pour vous accompagner dans toutes les études nécessaires
      et adaptées à votre projet.
    </p>

    <video class="architectes-video" autoPlay playsinline muted loop ref="videoArchitectes">
      <source src="@/assets/architectes.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'architectes-page',

  mounted() {
    const video = this.$refs.videoArchitectes;
    const img = this.$refs.architectesImg;
    const text = this.$refs.textDescription;
    const title = this.$refs.architectesTitle;

    ScrollTrigger.create({
      trigger: '.architectes',
      start: 'top top',
      end: 'center center',
      scrub: true,

      onUpdate: (self) => {
        const progress = self.progress;
        gsap.to(video, {scale: 0.8 + progress * 0.2 });
        gsap.to(img, {opacity: progress });
        gsap.to(title, {opacity: progress });
        gsap.to(text, {opacity: progress, bottom: 35 + progress * -35 + '%' });
      }
    });

    ScrollTrigger.create({
      trigger: '.architectes',
      start: 'top top',
      end: 'bottom bottom',

      onLeave: () => {
        video.style.position = 'absolute';
        video.style.bottom = '0';
        video.style.top = 'unset';

        img.style.position = 'absolute';

        if(window.innerWidth > 425){
          img.style.top = '60%';
        } else{
          img.style.top = '62%';
        }


        title.style.position = 'absolute';
        title.style.top = '67%';

        text.style.position = 'absolute';
        text.style.top = '79%';

      },
      onEnterBack: () => {
        video.style.position = 'fixed';

        img.style.position = 'fixed';
        if(window.innerWidth > 425){
          img.style.top = '20%';
        } else{
          img.style.top = '25%';
        }

        title.style.position = 'fixed';
        title.style.top = '35%';

        text.style.position = 'fixed';
        text.style.top = '60%';
      }
    });
  }
};
</script>

<style scoped lang="scss">
.architectes {
  position: relative;
  height: 200vh;
  width: 100%;
  background-color: #0B1A36;
  overflow: hidden;
}

.architectes .title{
  font-size: 4rem;
  font-family: 'Lexend Deca', sans-serif;
  color: white;
  font-weight: 100;
  z-index: 11;
  position: fixed;
  opacity: 0;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.architectes .la-signature {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  opacity: 0;
  font-size: 100px;
  font-family: 'Manier-Bold', sans-serif;
  color: #fff;
  white-space: nowrap;
  
  @media (max-width: 768px) {
    top: 25%;
    font-size: 50px;
  }
}

.title{
  width: 100%;
  text-align: center;
}

.architectes p {
  position: fixed;
  z-index: 10;
  max-width: 95%;
  width: 900px;
  color: white;
  font-size: 2rem;
  font-weight: 200;
  font-family: 'Lexend Deca', sans-serif;
  text-align: center;
  opacity: 0;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
}

.architectes-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0.95;
  scale: 0.8;
}
</style>
