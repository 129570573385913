<template>
  <div class="loading-bg">
    <p class="percent">{{ percent }}%</p>
    <h1 class="loading-title">Chargement.</h1>
  </div>

</template>

<script>
export default {
  name: 'loading-logo',

  data() {
    return {
      percent: 0
    };
  },

  mounted() {
  },

  created() {
    this.increment();
  },

  methods: {
    increment() {
      let intervalId = setInterval(() => {
        if (this.percent < 100) {
          this.percent++;
        } else {
          clearInterval(intervalId);
        }
      }, 40);
    }
  },
}
</script>

<style lang="scss">
.loading-bg{
  background-color: #0B1A36;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .percent{
    color: #fff;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: "Lexend Deca", sans-serif;
    position: absolute;
    bottom: 80px;
  }

  .loading-title{
    color: #fff;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: "Lexend Deca", sans-serif;
    position: absolute;
    bottom: 50px;
  }
}

</style>