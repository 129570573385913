<template>
  <a @click="scrollTo('contact')" class="devis" ref="devis">
    <img src="@/assets/icon-tel.png" alt="devis" class="devis-img" />
    <p>Demande de rappel</p>
  </a>
  <div class="home" ref="homeContainer" id="etudes-prealables">
    <h1 class="etudes-title" ref="etudes">Études</h1>
    <p class="etudes-subtitle" ref="prealable">préalables</p>

    <p class="etudes-text" ref="text1">
      Lorsque votre projet est en phase de conception, nos équipes peuvent réaliser pour vous les métrés, plans,
      dessins, 3D, études thermiques etc.
      <br/>
      Vous êtes également accompagnés dans vos démarches de demande de permis de construire et déclaration de travaux.
    </p>
    <video class="etudes" muted autoplay loop ref="videoEtudes">
      <source src="@/assets/etudes.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'HelloWorld',

  mounted() {

    gsap.set(this.$refs.devis, { display: 'flex', delay: 5 });

    ScrollTrigger.create({
      trigger: '.home',
      start: 'top top',
      end: 'center center',
      scrub: true,

      onEnter: () => {
        this.$refs.videoEtudes.style.position = 'fixed';
      },

      onUpdate: (self) => {
        const progress = self.progress;

        if(window.innerWidth > 768){
          gsap.to(this.$refs.videoEtudes, {
            scale: 1 - (progress * 0.5),
            left: (progress * -18) + '%',
          });
        } else {
          gsap.to(this.$refs.videoEtudes, {
            scale: 1 - (progress * 0.5),
            left: (progress * -18) + '%',
            opacity: 1 - progress,
          });
        }

        gsap.to(this.$refs.etudes, { opacity: progress, left: (progress * 48) + '%' });
        gsap.to(this.$refs.prealable, { opacity: progress, right: (progress * 12) + '%' });
      },

      onLeaveBack: () => {
        const progress = self.progress;
        gsap.to(this.$refs.videoEtudes, { scale: progress });
      },
    });

    ScrollTrigger.create({
      trigger: '.home',
      start: 'center 90%',
      end: 'center 30%',
      scrub: true,

      onUpdate: (self) => {
        const progress = self.progress;
        gsap.to(this.$refs.text1, { opacity: progress, top: 73 - (progress * 29) + '%' });
      }
    })

    ScrollTrigger.create({
      trigger: '.home',
      start: 'top top',
      end: 'bottom bottom',
      refreshPriority: 1,

      onLeave: () => {
        this.$refs.videoEtudes.style.position = 'absolute';
        this.$refs.videoEtudes.style.bottom = '0px';
        this.$refs.videoEtudes.style.top = 'unset';

        this.$refs.etudes.style.position = 'absolute';
        if(window.innerWidth < 768) {
          this.$refs.etudes.style.top = '78%';
          this.$refs.prealable.style.top = '82.2%';
        } else {
          this.$refs.etudes.style.top = '75.8%';
          this.$refs.prealable.style.top = '80.6%';
        }

        this.$refs.prealable.style.position = 'absolute';


        this.$refs.text1.style.position = 'absolute';
        this.$refs.text1.style.top = '86%';
      },

      onEnterBack: () => {
        this.$refs.videoEtudes.style.position = 'fixed';
        this.$refs.videoEtudes.style.bottom = 'unset';
        this.$refs.videoEtudes.style.top = '0px';

        this.$refs.etudes.style.position = 'fixed';

        if(window.innerWidth < 768) {
          this.$refs.etudes.style.top = '13%';
          this.$refs.prealable.style.top = '29%';
        } else {
          this.$refs.etudes.style.top = '3%';
          this.$refs.prealable.style.top = '22%';
        }

        this.$refs.prealable.style.position = 'fixed';

        this.$refs.text1.style.position = 'fixed';
        this.$refs.text1.style.top = '44%';
      }
    })
  },

  methods: {

    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth' });
    },
  }
};
</script>
<style scoped lang="scss">
.home{
  position: relative;
  height: 400vh;
  width: 100%;
  background-color: #0B1A36;
  z-index: 4;
  overflow: hidden;
}

.etudes {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.9;
  z-index: 1;
}

.etudes-title{
  position: fixed;
  font-family: 'Manier-Bold', serif;
  font-size: 8rem;
  font-weight: 400;
  color: #fff;
  opacity: 0;
  left: 20%;
  top: 3%;
  z-index: 2;

  @media (max-width: 1400px) {
    font-size: 5rem;
    margin-top: 6rem;
  }

  @media (max-width: 768px) {
    margin-top: 5rem;
    font-size: 3.5rem;
    left: 10%;
    top: 13%;
  }
}

.etudes-subtitle{
  font-size: 90px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 100;
  color: #fff;
  opacity: 0;
  position: fixed;
  right: -40%;
  top: 22%;
  z-index: 20;

  @media (max-width: 1400px) {
    font-size: 4rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    right: 10%;
    top: 29%;
  }
}

.etudes-text{
  font-size: 25px;
  font-family: 'Lexend Deca', sans-serif;
  color: #fff;
  position: fixed;
  right: 10%;
  top: 73%;
  z-index: 2;
  width: 500px;
  opacity: 0;
  padding: 16px;
  font-weight: 200;

  @media (max-width: 768px) {
    font-size: 20px;
    right: -2%;
    max-width: 100%;
  }
}

.text-2{
  top: 83%;
  right: 10%;

  @media (max-width: 768px) {
    right: -2%;
    max-width: 100%;
  }
}

.devis{
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 10%;
  transform: translateY(-50%);
  width: 110px;
  color: #000;
  font-weight: 300;
  font-family: "Lexend Deca", sans-serif;
  flex-direction: column;
  padding: 8px;
  align-items: center;
  justify-content: center;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #0000006F;
  border-radius: 8px 0 0 8px;
  opacity: 0.71;
  text-decoration: none;
  outline: none;
  display: none;

  p{
    font-size: .9rem;
    text-align: center;
    margin-bottom: 0;
    margin-top: 5px;
  }

  img{
    width: 30px;
  }

  @media (max-width: 768px) {
    width: 70px;

    p{
      display: none;
    }
  }
}
</style>
