<template>
  <div class="fixed-transparent"></div>
  <div class="nav-button" @click="animateNav">
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </div>

  <div class="close-button" ref="closeButton" @click="closeNav">
    <div class="bar"></div>
    <div class="bar"></div>
  </div>


  <div class="nav" ref="navElement">
    <div class="nav-wrapper">
      <nav>
        <ul class="menu_list">
          <li>
            <a @click.prevent="scrollTo('etudes-prealables')" href="/#etudes-prealables">Études préalables</a>
          </li>
          <li>
            <a @click.prevent="scrollTo('architectes')" href="/#architectes">Architectes</a>
          </li>
          <li>
            <a @click.prevent="scrollTo('architectes-interieur')" href="/#architectes-interieur">Architectes d'intérieur</a>
          </li>
          <li>
            <a @click.prevent="scrollTo('premium')" href="/#premium">Activ travaux premium</a>
          </li>
          <li>
            <a @click.prevent="scrollTo('tendances')" href="/#tendances">Tendances</a>
          </li>
          <li>
            <a @click.prevent="scrollTo('contact')" href="/#contact">Contact</a>
          </li>

          <div class="footer">
           <div class="footer-socials">
              <a href="https://www.facebook.com/activtravaux/" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook-f']" />
              </a>
              <a href="https://www.instagram.com/activtravaux/" target="_blank">
                <font-awesome-icon :icon="['fab', 'instagram']" />
              </a>
              <a href="https://www.linkedin.com/company/activ-travaux/" target="_blank">
                <font-awesome-icon :icon="['fab', 'linkedin-in']" />
              </a>
            </div>
            <div class="footer-legals">
              <a>Politique de confidentialité</a>
              <a>Mentions légales</a>
              <a>© 2023 Activ Travaux</a>
            </div>
          </div>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFacebookF} from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";


library.add(faFacebookF);
library.add(faInstagram);
library.add(faLinkedinIn);

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'NavBar',
  components: {FontAwesomeIcon},

  mounted() {
    gsap.set(this.$refs.navElement, { y: '-100%' });
    gsap.set('.menu_list li', { y: '-55px', opacity: 0 });
    gsap.set(this.$refs.closeButton, { opacity: 0 });
    gsap.set('.nav-button .bar', { width: 30 });
    gsap.set('.footer div', { opacity: 0 });

    window.addEventListener('scroll', () => {
      if (window.scrollY > 5) {
        document.querySelector('.fixed-transparent').classList.add('active');
      } else {
        document.querySelector('.fixed-transparent').classList.remove('active');
      }
    });
  },


  methods: {
    animateNav() {
      const tl = gsap.timeline();

      gsap.utils.toArray('.nav-button .bar').forEach((bar, i) => {
        tl.to(bar, { width: 0, duration: 0.1, delay: i * 0.025 });
      });

      tl.fromTo(this.$refs.navElement, {y: '-100%'},{duration: 0.8, y: 0, ease: "Power3.easeInOut"});
      tl.fromTo(this.$refs.closeButton, { opacity: 0 }, { opacity: 1, duration: 0.2, ease: "Power3.easeInOut" });
      tl.fromTo('.menu_list li', {y: '55px', opacity: 0}, { y: 0, opacity: 1, ease: "Power3.easeInOut", stagger: 0.1 });
      tl.fromTo('.footer div', { opacity: 0 }, { opacity: 1, duration: 0.2, stagger: 0.1, ease: "Power2.easeInOut" });
    },


    closeNav() {
      const tl = gsap.timeline();

      tl.to(this.$refs.closeButton, { opacity: 0, duration: 0.2 });
      tl.fromTo('.menu_list li', {y: 0, opacity: 1}, { y: '-55px', opacity: 0, stagger: 0.1, ease: "Power3.easeInOut" });
      tl.fromTo('.footer div', { opacity: 1 }, { opacity: 0, duration: 0.2, stagger: 0.1, ease: "Power2.easeInOut" });
      tl.to(this.$refs.navElement, { y: '100%', duration: 0.4, ease: "Power2.easeInOut" });
      tl.to('.nav-button .bar', { width: 30, duration: 0.1, stagger: 0.1, ease: "Power3.easeInOut" });
    },

    scrollTo(id) {
      this.closeNav();

      const element = document.getElementById(id);
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">

.nav{
  z-index: 200;
  position: fixed;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  padding: 5.5vh 8.3vw;
  overflow: hidden;
  background-color: #0B1A36;
}


@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.footer{
  position: absolute;
  bottom: -100px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Lexend Deca", sans-serif;
  font-size: 0.8rem;
  font-weight: 300;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .footer-socials, .footer-legals{
    display: none;//flex
    align-items: center;
    justify-content: start;

    a{
      margin-right: 1.2rem;
      color: #fff;
      text-decoration: none;
    }
  }

  .footer-legals{

    a{
      margin-right: 0;
      margin-left: 1.2rem !important;
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
      justify-content: start;

      a{
        margin-left: 0 !important;
        margin-top: 10px;
      }
    }
  }
}

.close-button{
  z-index: 888;
  position: fixed;
  left: 30px;
  top: 30px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  border: 1px solid #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;

  &:hover {
    animation: heartbeat 0.25s ease-in-out;
  }

  .bar{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 1px;
    background-color: #fff;
    transform: translate(-50%, -50%) rotate(45deg);

    &:nth-child(2){
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.nav-button{
  z-index: 999;
  position: fixed;
  left: 20px;
  top: 5px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  .bar{
    width: 30px;
    height: 1px;
    background-color: #fff;
    align-self: flex-end;

    &:nth-child(2){
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

.nav-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media (max-width: 768px) {
    height: 70%;
  }
}

.menu_list{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  align-items: start;

  li{
    font-size: 2.5rem;
    letter-spacing: 1px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    margin-bottom: 3vh;
    font-weight: 100;

    a{
      text-decoration: none;
      color: #fff;
      outline: none;
    }
  }

  @media (max-width: 768px) {
    margin-top: 3rem;

    li{
      font-size: 1.5rem;
    }
  }
}

@supports (backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)) {
  .nav {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

.fixed-transparent{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  pointer-events: none;
  z-index: 100;
  background-color: rgba(11, 26, 54, 0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(20px);

  &.active{
    opacity: 1;
    pointer-events: all;
  }
}

</style>
