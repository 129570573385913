<template>
  <div id="app">
    <loading-logo v-if="!isLoaded" />
    <div v-else>
      <NavBar />
      <LoadingScreen />
      <Home />
      <architectes-page />
      <architectes-interieur />
      <AtPremium />
      <AtTendances />
      <AtContact />
    </div>
  </div>
</template>

<script>
import LoadingScreen from './components/LoadingScreen.vue';
import Home from './components/Home.vue'
import ArchitectesPage from "@/components/Architectes.vue";
import ArchitectesInterieur from "@/components/ArchitectesInterieur.vue";
import NavBar from "@/components/NavBar.vue";
import AtPremium from "@/components/AtPremium.vue";
import AtTendances from "@/components/AtTendances.vue";
import AtContact from "@/components/AtContact.vue";
import LoadingLogo from "@/components/loadingLogo.vue";

export default {
  name: 'App',
  components: {
    LoadingLogo,
    AtContact,
    AtTendances,
    AtPremium,
    NavBar,
    ArchitectesInterieur,
    ArchitectesPage,
    LoadingScreen,
    Home,
  },

  data: function() {
    return {
      isLoaded: false
    }
  },

  mounted() {
    //document.body.style.overflow = 'hidden';
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    setTimeout(() => {
      this.isLoaded = true;
    }, 4000);
  }
}
</script>

<style>
@font-face {
  font-family: 'Manier-Bold';
  src: url('@/assets/fonts/Manier-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Manier-Medium';
  src: url('@/assets/fonts/Manier-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



*,
*::before,
*::after {
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
}
*:focus {
  outline: 3px dashed #228bec;
}

body{
  margin: 0;
  padding: 0;
  background-color: #0B1A36; /* #0D1424 */
}

h1,
h2 {
  margin-bottom: 0;
}
ul {
  list-style: none;
  padding: 0;
}
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
}
button::-moz-focus-inner {
  border: 0;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  /* 1 */
  overflow: visible;
}
input[type="text"] {
  border-radius: 0;
}
/*END RESETS*/

</style>
