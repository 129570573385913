<template>
  <div class="tendances" id="tendances">
    <video autoplay muted loop playsinline ref="videoPremium" class="tendances__video">
      <source src="@/assets/tendances.mp4" type="video/mp4">
    </video>

    <div class="tendances__wrapper">
      <div class="tendances__wrapper__text">
        <img src="@/assets/logo-tendances.png" class="tendances__wrapper__logo" ref="logoTendances">
        <p ref="textTendances">
          Nous vous proposons des gammes de produits de grands
          fabricants pour votre aménagement de :
        </p>

        <div class="tendances__wrapper__text_title" ref="titleAnimation">
          <h1>Cuisine</h1>
          <h1>Salle de bain</h1>
          <h1>Revêtements de sol</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'at-tendances',

  mounted() {
      const titleAnimation = this.$refs.titleAnimation.children;

      ScrollTrigger.create({
        trigger: '.tendances',
        start: 'top 50%',
        end: 'bottom bottom',
        onEnter: () => {
          gsap.to(Array.from(titleAnimation), {
            duration: 1,
            opacity: 1,
            stagger: 0.25,
            ease: "power2.out",
            overwrite: 'auto',
            delay: 0.25,
          });

          gsap.to(this.$refs.logoTendances, {
            duration: 1,
            opacity: 1,
            ease: "power2.out",
            overwrite: 'auto',
            delay: 0.25,
            transform: "translateY(0)"
          });
        },
      });
    },

  methods: {
  }
};
</script>


<style scoped lang="scss">
.tendances {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__wrapper {
    background-color: rgba(11, 26, 54, 0.3);
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    align-items: center;
    display: flex;
    flex-direction: column;

    &__logo{
      margin-top: 5rem;
      width: 500px;
      max-width: 100%;
      opacity: 0;
      transform: translateY(-100);

      @media (max-width: 768px) {
        margin-top: 2rem;
      }
    }

    &__text{
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      p{
        color: #fff;
        font-size: 1.3rem;
        text-align: center;
        margin-bottom: 2rem;
        font-weight: 200;
        width: 100%;
        max-width: 500px;
        font-family: "Lexend Deca", sans-serif;
      }

      h1{
        color: #fff;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 1rem;
        font-weight: 400;
        font-family: "Lexend Deca", sans-serif;
        opacity: 0;
      }
    }

  }
}
</style>
