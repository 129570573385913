<template>
  <div class="architectes-interieur" id="architectes-interieur">
    <h1 class="la-signature" ref="architectesInterieurImg">La Signature</h1>
    <h1 class="architectes-title" ref="title">de l'architecte d'intérieur</h1>

    <p class="text-1" ref="textDescriptionInterieur">
      Pour la réussite de votre projet, d'aménagement, Activ Travaux vous met en relation avec son réseau d'architectes
      d'intérieur partenaires.
    </p>
    <p class="text-2" ref="textDescriptionInterieur2">
      Des solutions d’aménagement sur-mesure, conseil en décoration, optimisation des espaces, agencement.
    </p>


    <video class="architectes-interieur-video" playsinline autoplay loop muted ref="videoArchitectesInterieur">
      <source src="@/assets/archi-interieur.mp4" type="video/mp4">
    </video>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'architectes-interieur',

  mounted() {
    const video = this.$refs.videoArchitectesInterieur;
    const img = this.$refs.architectesInterieurImg;
    const textDescriptionInterieur = this.$refs.textDescriptionInterieur;
    const textDescriptionInterieur2 = this.$refs.textDescriptionInterieur2;
    const title = this.$refs.title;

    ScrollTrigger.create({
      trigger: '.architectes-interieur',
      start: 'top top',
      end: 'bottom bottom',
      scrub: true,

      onEnter: () => {
        gsap.set(video, { position: 'fixed', bottom: 'unset', top: 0 });
      },

      onEnterBack: () => {
        gsap.set(video, { position: 'fixed', bottom: 'unset', top: 0 });
      },

      onUpdate: (self) => {
        video.pause()

        video.currentTime = video.duration * self.progress;
        video.style.transform = `scale(${1 - self.progress * 0.5})`;

        if(window.innerWidth > 768){
          video.style.left = `${self.progress * -18}%`;
        } else{
          video.style.left = `${1 - self.progress}%`;
          video.style.opacity = `${1 - self.progress}`;
        }
      },

      onLeaveBack: () => {
        gsap.set(video, { position: 'absolute', scale: 1, bottom: 'unset', top: 0});
        console.log('leave back');
      },

      onLeave: () => {
        gsap.set(video, { position: 'absolute', bottom: '0', top: 'unset' });
      },
    });

    ScrollTrigger.create({
      trigger: '.architectes-interieur',
      start: 'center center',
      end: 'bottom bottom',
      scrub: true,

      onEnter: () => {
        gsap.set(img, { position: 'fixed' });
        gsap.set(textDescriptionInterieur, { position: 'fixed' });
        gsap.set(textDescriptionInterieur2, { position: 'fixed' });
        gsap.set(title, { position: 'fixed' });
      },

      onUpdate: (self) => {
        const progress = self.progress;
        gsap.to(img, { opacity: progress});
        gsap.to(title, { opacity: progress });
        gsap.to(textDescriptionInterieur, { opacity: progress });
        gsap.to(textDescriptionInterieur2, { opacity: progress });
      },

      onLeave: () => {
        gsap.set(img, { position: 'absolute', top: '209vh'});

        if(window.innerWidth < 768) {
          gsap.set(textDescriptionInterieur, { position: 'absolute', top: '230vh'});
          gsap.set(textDescriptionInterieur2, { position: 'absolute', top: '247vh'});
          gsap.set(title, { position: 'absolute', top: '220vh'});
        } else {
          gsap.set(textDescriptionInterieur, { position: 'absolute', top: '240vh'});
          gsap.set(textDescriptionInterieur2, { position: 'absolute', top: '255vh'});
          gsap.set(title, { position: 'absolute', top: '224vh'});
        }
      },

      onLeaveBack: () => {
        gsap.set(img, { position: 'fixed', top: '9vh'});



        if (window.innerWidth < 768) {
          gsap.set(textDescriptionInterieur, { position: 'fixed', top: '30vh'});
          gsap.set(textDescriptionInterieur2, { position: 'fixed', top: '47vh'});
          gsap.set(title, { position: 'fixed', top: '20vh'});
        } else {
          gsap.set(textDescriptionInterieur, { position: 'fixed', top: '40vh'});
          gsap.set(textDescriptionInterieur2, { position: 'fixed', top: '505h'});
          gsap.set(title, { position: 'fixed', top: '24vh'});
        }
      },

      onEnterBack: () => {
        gsap.set(img, { position: 'fixed', top: '9vh'});

        if (window.innerWidth < 768) {
          gsap.set(textDescriptionInterieur, { position: 'fixed', top: '30vh'});
          gsap.set(textDescriptionInterieur2, { position: 'fixed', top: '47vh'});
          gsap.set(title, { position: 'fixed', top: '20vh'});
        } else {
          gsap.set(textDescriptionInterieur, { position: 'fixed', top: '40vh'});
          gsap.set(textDescriptionInterieur2, { position: 'fixed', top: '55vh'});
          gsap.set(title, { position: 'fixed', top: '24vh'});
        }
      },
    })
}
};
</script>



<style scoped lang="scss">
.architectes-interieur {
  position: relative;
  height: 300vh;
  width: 100vw;
  max-width: 100%;
  background-color: #0B1A36;
  z-index: 15;
  overflow: hidden;

  p, .architectes-title{
    z-index: 16;
    max-width: 95%;
  }

  p{
    font-family: "Lexend Deca", sans-serif;
    font-size: 1.5rem;
    color: #fff;
    width: 500px;
    padding: 1rem 1.8rem 1rem 1rem;
    max-width: 100%;
  }
}

.architectes-interieur-video {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 1;
  z-index: 15;
}

.architectes-title{
  font-size: 4rem;
  font-family: 'Lexend Deca', sans-serif;
  color: white;
  font-weight: 100;
  z-index: 11;
  position: absolute;
  opacity: 0;
  top: 24vh;
  right: 8vw;

  @media (max-width: 768px) {
    font-size: 1.9rem;
    top: 20vh;
  }
}

.architectes-interieur .la-signature{
  position: absolute;
  top: 9vh;
  left: 30vw;
  z-index: 16;
  opacity: 0;
  font-size: 100px;
  font-weight: 300;
  font-family: 'Manier-Bold', serif;
  color: #fff;
  white-space: nowrap;

  @media (max-width: 768px) {
    left: 15vw;
    font-size: 50px;
  }
}

.architectes-interieur p{
  position: absolute;
  opacity: 0;
  font-weight: 200;
}

.text-1{
  top: 40vh;
  right: 8vw;
}

.text-2{
  top: 55vh;
  right: 8vw;
}

@media (max-width: 768px) {
  .text-1{
    top: 30vh;
  }

  .text-2 {
    top: 47vh;
  }

  .text-1, .text-2 {
    font-size: 1.3rem !important;
    right: -15px;
  }

  .text-2{
    margin-top: 3.2rem;
  }
}
</style>
